
import React from 'react';
import './Home.css';
import HeroSection from '../components/HeroSection';
import Carousel from './Carousel';
import ApplicationForm from './ApplicationForm';
import Founder from './Founder';
import MissionSection from '../components/MissionSection';
import FacilitiesSection from '../components/FacilitiesSection';
import VideoSection from '../components/VideoSection';
import FaqComponent from '../components/FaqComponent';
import FacilityList from './facilities';
import EventsSection from './EventSection.js';

const Home = () => {
  return (
    <>
    <div className='mainSection'>
    <Carousel />
    <ApplicationForm/>
    </div>
    <MissionSection /> 
    <FacilityList/>
    <FacilitiesSection/>
    <EventsSection/>
    <VideoSection/>
    <FaqComponent/>
    </>
  );
};

export default Home;



