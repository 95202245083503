import React from 'react';
import './AboutUs.css';
import amitKesarwani from "../images/amitKesarwani.png";
import headOfSchool from "../images/head-of-school.png";
import founder from "../images/founder-1.png";
import tatsatMishra from "../images/tatsatMishra.png";
import poonamMishra from "../images/poonamMishra.png";
import meghaAhuja from "../images/meghaAhuja-1.png";


export default function AboutUs() {
  return <div className='aboutus'>


    <div className='history'>
   


    <div className='container container-founder'>
       <section className='head'>
       <div className='head-image'>
        <img src={founder} alt='school' />
        </div>
        <div className='head-content'>
        
      <h2 className='founder-text'>SAKET MISHRA</h2>
     <h3 className='founder-text'>PEETHADHEESHWAR TULSI PEETH</h3>
     <p className='founder-text'>
     A distinguished gold medalist from Allahabad University with a rich academic
 background. For the past 30 years, he has been passionately dedicated his life to
 social activism, specifically focusing on advocating for the rights and well-being of
 children. Mr.Mishra’s dual expertise in academia and social service brings a unique
 blend of knowledge and compassion to our school community, inspiring students to
 engage in meaningful contributions to society.
     </p>
        </div>
       </section>
    </div>

 <div className='container container-founder'>
       <section className='head head-padding'>
        <div className='head-content'>
        
      <h2 className='founder-text'>TATPAR MISHRA</h2>
     <h3 className='founder-text'>HEAD OF SCHOOL</h3>
     <p className='founder-text'>
     An NIT/IIM graduate whose educational prowess and innovative mindset have
brought a fresh perspective to our school leadership. Armed with a degree from one
of India’s premier institutions, our head combines a strong academic foundation
with a passion for nurturing young minds. With a commitment to excellence,
technological acumen and a forward - thinking approach, they bring a wealth of
experience from the tech world to elevate the educational experience at our school.
     </p>
        </div>
        <div className='head-image'>
        <img src={headOfSchool} alt='school' />
        </div>
       </section>
    </div>


    <div className='heading'>
    <h1>Mentors</h1>
    {/* <p>bcjhebryfgerbjfdhb fbjherbgfvuyer bjehbgvj jehfcbjehdrb</p> */}
    </div>


    <div className='container add-cen'>
    <div className='founders'>

  <div className='founder-section'>
    <div className='founder-image'>
      <img src={amitKesarwani} alt='Founder 1' />
    </div>
    <div className='founder-content'>
      <h2>Amit Kesarwani</h2>
      <p>B-Tech, NIT Jalandhar</p>
      <p>Founder - Narottam Foundation, Allahabad</p>
      <p>Financial Services Background,</p>
      <p>TATA 1MG - Gurgaon</p>
    </div>
  </div>

  <div className='founder-section'>
    <div className='founder-image'>
      <img src={tatsatMishra} alt='Founder 1' />
    </div>
    <div className='founder-content'>
      <h2>Tatsat Mishra</h2>
      <p>B-Tech, NIT Jaipur</p>
      <p>Healthcare and FMCG</p>
      <p>Expansion Manager</p>
      <p>TMinistry of Telecommunication</p>
    </div>
  </div>

  <div className='founder-section'>
    <div className='founder-image'>
      <img src={meghaAhuja} alt='Founder 1' />
    </div>
    <div className='founder-content'>
      <h2>Megha Ahuja</h2>
      <p>Data Scientist</p>
      <p>Computer Science Engineer</p>
      <p>Sustainability and Conscious</p>
      <p>Living Advocate</p>
    </div>
  </div>

  <div className='founder-section'>
    <div className='founder-image'>
      <img src={poonamMishra} alt='Founder 1' />
    </div>
    <div className='founder-content'>
      <h2>Poonam Mishra</h2>
      <p>BED, BA/MA Delhi University</p>
      <p>IIFT GZB - Fashion Arts</p>
      <p>Writer</p>
      <p>Social Activist</p>
    </div>
  </div>


 
</div>

    </div>


    

    </div>








   

  </div>;
}





// import React from 'react';
// import './AboutUs.css';
// import saketMishra from "../images/saketMishra.png";
// import tatparMishra from "../images/tatparMishra.png";

// export default function AboutUs() {
//   return <div>
//   <div className='headings'>
// <h1>About Us</h1>
// </div>

// <div>
// <div className="container-for-head container-for-head-1">
// <div className="image-container-for-head">
//     <img
//       src={saketMishra} // Example image source, replace with your image source
//       alt="Example"
//     />
//   </div>
//   <div className="text-container-for-head text-container-for-head-1">
//     <h2>SAKET MISHRA</h2>
//     <h3>PEETHADHEESHWAR TULSI PEETH</h3>
//     <p >
//     A distinguished gold medalist from Allahabad University with a rich academic
// background. For the past 30 years, he has been passionately dedicated his life to
// social activism, specifically focusing on advocating for the rights and well-being of
// children. Mr.Mishra’s dual expertise in academia and social service brings a unique
// blend of knowledge and compassion to our school community, inspiring students to
// engage in meaningful contributions to society.
//     </p>
//   </div>
// </div>
// </div>



// <div className="container-for-head container-for-head-1">
//   <div className="text-container-for-head text-container-for-head-1">
//     <h2>SAKET MISHRA</h2>
//     <h3>PEETHADHEESHWAR TULSI PEETH</h3>
//     <p >
//     A distinguished gold medalist from Allahabad University with a rich academic
// background. For the past 30 years, he has been passionately dedicated his life to
// social activism, specifically focusing on advocating for the rights and well-being of
// children. Mr.Mishra’s dual expertise in academia and social service brings a unique
// blend of knowledge and compassion to our school community, inspiring students to
// engage in meaningful contributions to society.
//     </p>
//   </div>
//   <div className="image-container-for-head">
//     <img
//       src={tatparMishra} // Example image source, replace with your image source
//       alt="Example"
//     />
//   </div>
// </div>


// <div className='mentor-list'>
//   <div className="mentor-container">
   
//   </div>
// </div>

// </div>

// }