// AmenitiesPage.js

import React from 'react';
import './Amenities.css';
import Cards from '../components/Cards';

const Amenities = () => {
  return (
    <>
<Cards/>
    </>
    
  );
};

export default Amenities;
