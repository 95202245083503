import React from "react";
import "./ApplicationForm.css";
import YourPDFFile from "../images/PDF.pdf"; // Import your PDF file

const ApplicationForm = () => {
  const handleFormSubmit = (event) => {
    event.preventDefault();

    // Get form data
    const formData = new FormData(event.target);
    const name = formData.get("firstName");
    const email = formData.get("email");
    const phoneNumber = formData.get("phoneNumber");

    // Create a WhatsApp message with the user's information
    const whatsappMessage = `Hi, I am interested in admissions. My name is ${name}, my email is ${email}, and my phone number is ${phoneNumber}.`;

    // Construct the WhatsApp link
    const whatsappLink = `https://wa.me/8383871467?text=${encodeURIComponent(
      whatsappMessage
    )}`;

    // Open WhatsApp in a new window or tab
    window.open(whatsappLink, "_blank");
  };

  const handleDownloadNowClick = () => {
    // Use the imported PDF file
    const pdfUrl = YourPDFFile;

    // Trigger the download
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "Gurukul.pdf";
    link.click();
  };

  return (
    <div className="admissions-form">
      <h2>Admissions Open</h2>

      <form onSubmit={handleFormSubmit}>
        <div className="form-group">
          <label htmlFor="firstName">Name:</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            placeholder="Enter your name"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email Address:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email address"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number:</label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            placeholder="Enter your phone number"
            required
          />
        </div>
        <button className="form-button" type="submit">Apply Now</button>
        <button className="form-button" type="submit" onClick={handleDownloadNowClick}>Download</button>
      </form>
    </div>
  );
};

export default ApplicationForm;