import React from "react";
import "./Cards.css";
import CardItem from "./CardItem";
import founder from "../images/founder-1.png";
import camera from "../images/camera.jpg";
import library from "../images/library.jpg";
import diet from "../images/diet.jpg";
import classrooom from "../images/classroom.jpg";
import transport from "../images/transport.jpg";
import cafe from "../images/cafetria.jpeg";
import tech from "../images/tech.jpeg";

function Cards() {
  return (
    <div className="cards">
      <h1>Amenities</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src={diet}
              text="At Gurukul Vidya, with our in-house cafeteria, children are offered nutritious meals
              that ensure their wellbeing. We provide 1300 calories per day to every kid as
              proposed by WHO (World Health Organisation) guidelines. The diet plan is created
              by a qualified nutritionist and includes a variety of cuisines that keeps the food
              appealing to children. Great care is undertaken to ensure that different food groups
              that offer a variety of essential nutrients to children are included in the menu and
              that the menu is revised every week"
              label="Diet and Nutrition Care"
            />
            <CardItem
              src={classrooom}
              text="Our school is equipped with modern and spacious classrooms that provide a
              conducive learning environment. Each classroom is fitted with the latest educational
              technology to enhance the teaching and learning experience."
              label="State-of-the-Art Classrooms"
            />
            <CardItem
              src={library}
              text="Our school library is a treasure trove of knowledge, featuring an extensive collection
              of books, journals, and reference materials. It serves as a quiet and comfortable space
              for students to explore and expand their intellectual horizons"
              label="Library"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src={classrooom}
              text="Physical fitness is an integral part of our holistic approach to education. Our school
              boasts a range of sports facilities, including a sports field, swimming pool, badminton
              court and indoor sports arenas. We encourage students to participate in various
              sports to promote a healthy and active lifestyle."
              label="Sports Facilities"
            />
            <CardItem
              src={transport}
              text="We celebrate creativity and self-expression. Our dedicated spaces for arts and
              cultural activities provide students with the opportunity to explore their talents in
              music, dance, drama, and the visual arts."
              label="Arts and Cultural Spaces"
            />
            <CardItem
              src={cafe}
              text="Our cafeteria offers nutritious and delicious meals to keep students energized
              throughout the day. We prioritize providing a variety of healthy food options to cater
              to different dietary preferences."
              label="Cafetria"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src={transport}
              text="Ensuring the safety and convenience of our students, we offer a reliable
              transportation system. Our fleet of vehicles is well-maintained, and experienced
              drivers ensure a secure commute to and from school."
              label="Transportation"
            />
            <CardItem
              src={tech}
              text="Embracing the digital age, we integrate technology into our teaching methods.
              Students have access to computer labs, and teachers use interactive tools to make
              learning more engaging and interactive."
              label="Technology Integration"
            />
            <CardItem
              src={camera}
              text="The safety of our students is our top priority. Our school is equipped with modern
              security measures, including surveillance cameras and trained security personnel, to
              create a secure and nurturing environment."
              label="Security Measures"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
