import React from 'react';
import './Curriculum.css';
import cBSE from '../images/CBSE_Logo.jpg';

const Curriculum = () => {
  return (

    <div class="cur-main">
   <div className='headings'>
    <h1>Affiliation</h1>
    </div>
    <div className='container container-founder'>
       <section className='head'>
       <div className='head-image cur-image'>
        <img src={cBSE} alt='school' />
        </div>
        <div className='head-content curr-content'>
        
      <h2 className='founder-text'>CBSE</h2>
     <p className='founder-text'>
     While we understand the value of international boards, the mainstream in India is
still CBSE. Being affiliated to CBSE, we will pursue the methods of learning
prescribed by international boards. Affiliating to international boards increases costs
for parents and also reduces options for them – therefore our decision is to remain
with CBSE, while innovating on all other fronts
     </p>
        </div>
       </section>
    </div>
    </div>
    
  );
};


export default Curriculum;

