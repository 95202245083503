import React, { useState } from 'react';
import './FaqComponent.css'; // Import your stylesheet

const FaqComponent = () => {
  const faqs = [
    {
      question: 'What grades does Gurukul Vidya cater to?',
      answer: 'Gurukul Vidya provides education from preschool to high school, covering grades from Nursery to Grade 12.',
    },
    {
      question: 'How can I apply for admission to Gurukul Vidya?',
      answer: 'Information about the admission process, including application forms and criteria, can be found on our website. Additionally, you can contact our admission office for assistance.',
    },
    {
      question: 'What is the curriculum followed at Gurukul Vidya?',
      answer: 'We follow a comprehensive curriculum that aligns with international standards, fostering academic excellence, creativity, and critical thinking.',
    },
    {
      question: 'Is there a focus on character development at Gurukul Vidya?',
      answer: 'Absolutely. Gurukul Vidya places a strong emphasis on character building, instilling values such as integrity, empathy, and social responsibility in our students.',
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
       <div className='headings headings-video'>
    <h1>FAQ</h1>
    </div>
      {faqs.map((faq, index) => (
        <div className="faq-item" key={index}>
          <div className="faq-question" onClick={() => handleToggle(index)}>
            <h3>{` ${faq.question}`}</h3>
            <span className={`dropdown-icon ${activeIndex === index ? 'active' : ''}`}>▶</span>
          </div>
          {activeIndex === index && <p className="faq-answer">{` ${faq.answer}`}</p>}
        </div>
      ))}
    </div>
  );
};

export default FaqComponent;
