import React from 'react';
import './MissionSection.css';

const MissionSection = () => {
  return (
    <div className='history-o'>
      <div className='mission-page'>
        <div className='headings .mission-heading'>
          <h1>Our Mission (USP)</h1>
        </div>
        <div className='container-h'>
          <section className='mission-section-text'>

            <p className="mission-text">
              At Gurukul Vidya, we are committed to cultivating a nurturing educational
              environment that empowers students to embark on a lifelong journey of
              learning, self-discovery, and personal growth. Our mission is to foster
              holistic development by seamlessly integrating academic excellence,
              character building.
            </p>
            <p className="mission-text">
              Gurukul Vidya is dedicated to instilling values that go beyond textbooks.
              We believe in nurturing the character of our students, promoting virtues
              such as integrity, empathy, resilience, and social responsibility. By
              fostering a sense of ethical responsibility, we aim to develop
              well-rounded individuals capable of making positive contributions to
              society.
            </p>
            <p className="mission-text">
              It has been founded by IIT-IIM alumni and intends to prepare young minds to
              act as leaders to promote healthy development and academic achievement as
              future citizens of the country.
            </p>
          </section>
        </div>
      </div>


    </div>
  );
};

export default MissionSection;
