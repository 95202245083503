import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Products from './pages/Products';
import Services from './pages/Services';
import SignUp from './pages/SignUp';
import AboutUs from './pages/AboutUs';
import Admissions from './pages/Admissions';
import Curriculum from './pages/Curriculum';
import Footer from './components/Footer';
import Amenities from './pages/Amenities';




function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/admissions" element={<Admissions />} />
          <Route path="/curriculum" element={<Curriculum />} />
          <Route path="/amenities" element={<Amenities />} />
          
        
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
