import React from "react";
import "./FacilitiesSection.css"; 
import badmintonImage from "../images/badminton.jpg";
import swimmingImage from "../images/swimming.jpg";
import runningImage from "../images/running.jpg";
import danceImage from "../images/dance.jpg";
import dramaImage from "../images/drama.jpg";
import footballImage from "../images/football.jpg";

const FacilitiesSection = () => {
  return (
    <>
      <div className="headings">
        <h1>Facilities</h1>
      </div>
      <div className="container-facilities">
        <div className="row-f">
          <div className="item">
            <img src={badmintonImage} alt="Badminton" />
            <p>Badminton</p>
          </div>
          <div className="item">
            <img src={footballImage} alt="Football" />
            <p>Football</p>
          </div>
          <div className="item">
            <img src={dramaImage} alt="Drama / Theatre" />
            <p>Drama / Theatre</p>
          </div>
        </div>

        <div className="row-f">
          <div className="item">
            <img src={danceImage} alt="Dance" />
            <p>Dance</p>
          </div>
          <div className="item">
            <img src={swimmingImage} alt="Swimming" />
            <p>Swimming</p>
          </div>
          <div className="item">
            <img src={runningImage} alt="Athletics" />
            <p>Athletics</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FacilitiesSection;
