import React from "react";
import "./VideoSection.css";

const VideoSection = () => {
  const videoUrl = "https://www.youtube.com/embed/uTXQYvcnrfM";

  return (
    <div className="history video-section-heads">
      <div className="headings headings-video">
        <h1>Video</h1>
      </div>
      <div className="video-section">
        <iframe
          width="100%"
          height="350"
          src={videoUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen=""
          title="Embedded YouTube Video"
        ></iframe>
      </div>
    </div>
  );
};

export default VideoSection;
