import React, { useState, useEffect } from 'react';
import './Carousel.css';
import s1 from '../images/homepage/DSC_0644.JPG';
import s3 from '..//images/homepage/Copy of IMG_4408.JPG';
import s4 from '../images/homepage/1721989890761.jpg'; 
import s5 from '../images/homepage/Copy of IMG_4239.JPG'; 
import s6 from '../images/homepage/Copy of IMG_4238 (1).JPG'


const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [
    s1,
    s3,
    s4,
    s5,
    s6
  ];

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  useEffect(() => {
    // Automatically shift the carousel every 3 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      handleNext();
    }, 3000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return (
    <div className="carousel-container">
      <div className="carousel">
        <button onClick={handlePrev} className="carousel-button prev">&#8249;</button>
        <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} className="carousel-image" />
        <button onClick={handleNext} className="carousel-button next">&#8250;</button>
      </div>
    </div>
  );
};

export default Carousel;
