import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Logo from '../images/gurukul.jpg';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>

      <nav className='navbar'>

      <div className='navbar-container'>
      <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            <img src={Logo} alt='Gurukul Logo' className='logo-img' />
         
          </Link>
      <p classs="D(f)" className='school-name'>Gurukul Vidya Public School</p>
      </div>


        <div className='navbar-container'>
         
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/aboutus'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                About Us
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/curriculum'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Curriulum
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/amenities'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Amenities
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/admissions'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Admissions
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/admissions'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Careers
              </Link>
            </li>
          
           

          </ul>
          
        </div>
      </nav>
    </>
  );
}

export default Navbar;
