import React from 'react';
import './Admissions.css';

export default function Admissions() {
  return <div className='admissions'>
  
     <section className='headline-1'>
      <h1 className="headline-title">The new academic session begins in April every year</h1>

      <div className="admission-info">
        <h2>Age Criteria for admission to academic session 2024-2025</h2>
      </div>
     </section>


     <section className='headline-2'>
      <div className="age-criteria">
        <ul className='list-age-criteria'>
        <li>
              <img src="https://www.pragyanam.school/assets/images/check-circle-solid.svg" alt="points" width="20" height="20"/>  The age eligibility for Pre-Nursery is 2 years as on 31st March, 2024 
        </li>
        <li>
              <img src="https://www.pragyanam.school/assets/images/check-circle-solid.svg" alt="points" width="20" height="20"/>  The age eligibility for Nursery is 3 years as on 31st March, 2024
        </li>
        <li>
              <img src="https://www.pragyanam.school/assets/images/check-circle-solid.svg" alt="points" width="20" height="20"/>  The age eligibility for LKG is 4 years as on 31st March,2024
        </li>
        <li>
              <img src="https://www.pragyanam.school/assets/images/check-circle-solid.svg" alt="points" width="20" height="20"/>  The age eligibility for UKG is 5 years as on 31st March,2024
        </li>
        <li>
              <img src="https://www.pragyanam.school/assets/images/check-circle-solid.svg" alt="points" width="20" height="20"/>  The age eligibility for Grade 1 is 6 years as on 31st March,2024
        </li>
        </ul>
      </div>

     </section>

    <section className="headline-3">
      <h2>Admission Process</h2>
      <ul className='headline-3-admission'>
        <li><b>Step 1:</b>Complete the registration/application form online (<a href="https://gurukulvidya.co.in/" target="_blank" rel="noopener noreferrer">Online Registration</a>).</li>
        <li><b>Step 2:</b> Notification for a school tour on the informed date and time via SMS or call.</li>
        <li><b>Step 3:</b> Student interaction:
          <ul className='headline-3-admission-admission'>
            <li>Pre-Nursery/Nursery - Direct Admissions.</li>
            <li>LKG/UKG - Interaction based.</li>
            <li>Grade I & IV - Interaction with the Head of the Primary Wing.</li>
            <li>Grade V - IX will have to appear for an entrance exam followed by an interaction with the VP.</li>
          </ul>
        </li>
        <li><b>Step 4:</b> Student admission confirmed, submission of admission fees & caution money at the time of admission itself.</li>
      </ul>

      <p className="note">Kindly note that the number of seats in each class is limited, and the registration alone does not guarantee Admission.</p>

      <h2>Documents Required for Admission</h2>
      <ul className='heading-3-documents'>
        <li>Aadhar card of the parent.</li>
        <li>Birth certificate/Aadhar of child.</li>
        <li>Soft copy of the photo (child and parent).</li>
        <li>Residence and Office Address of the parent.</li>
        <li>Allergy if any (for child).</li>
        <li>Blood Group of the child.</li>
        <li>Contact no and email id for both parents.</li>
        <li>Rent Agreement.</li>
        <li>TC from the previous school.</li>
        <li>Proof of concession category.</li>
      </ul>

      <p className="note">For any other admissions related queries, you can write to us at <a href="mailto:hellogurukulvidya@gmail.com">hellogurukulvidya@gmail.com</a>. Alternatively, you could also call us at <a href="tel:91-9311042850">++91-8318858185</a> to book an appointment.</p>
    </section>

  </div>;
}