import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import Logo from '../images/gurukul.jpg';
import insta from "../images/insta.jpg";
import fb from "../images/fb.jpg";
import yt from "../images/yt.jpg";

const Footer = () => {
  return (
    <footer className="app-footer">
      <div className="footer-content">
        <div className="map-container">
          {/* Embed your map here */}
          <iframe
            title="School Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12345.678901234567!2d-75.123456!3d40.987654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDDCsDQ2JzM0LjMiTiA3NcKwMTInMzcuNyJX!5e0!3m2!1sen!2sus!4v1643621234567!5m2!1sen!2sus"
            class="map-footer-class"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
        <div className="footer-info-header">
          <h1>Gurukul Vidya Public School</h1>
        </div>
        <div className="footer-info">
          <p class="Details-p">M: hellogurukulvidya@gmail.com</p>
          <p class="Details-p">Email: hellogurukulvidya@gmail.com</p>
        </div>
         <div className="social-icons">
          <a href="https://www.instagram.com/gurukul_vidya_sitapur?igsh=MWV2bTMyNGluMmRjYQ=%20=" target="_blank">
            <img src={insta} alt="Instagram" />
          </a>
          <p>|</p>
          <a href="https://www.facebook.com/profile.php?id=61555326805512" target="_blank">
            <img src={fb} alt="Facebook" />
          </a>
          <p>|</p>
          <a href="https://www.youtube.com/watch?v=uTXQYvcnrfM" target="_blank">
            <img src={yt} alt="YouTube" />
          </a>
        </div>
        <div className="footer-info">
          <p>Copyright &copy; 2024 - Gurukul</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
