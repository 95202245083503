import React from 'react';
import './EventSection.css';

const EventSection = () => {
  const events = [
    { date: '10', month: 'June', event: 'Guru Arjan Dev Ji Shaheedi Diwas', color: '#4CAF50' },
    { date: '15', month: 'June', event: 'Fathers Day', color: '#FF9800' },
    { date: '16', month: 'June', event: 'Eid-al-Adha', color: '#F44336' },
    { date: '21', month: 'June', event: 'International Yoga Day', color: '#2196F3' }
  ];

  return (
    <div className="events-section">
      <h2>Events & Important Dates</h2>
      <div className="events-container">
        {events.map((event, index) => (
          <div key={index} className="event-card" style={{ backgroundColor: event.color }}>
            <div className="date">
              <h1>{event.date}</h1>
              <span>{event.month}</span>
            </div>
            <p className='eventDetail'>{event.event}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventSection;
