import React, { useState, useEffect } from 'react';
import './facility.css';

const Facility = [
  {
    image: [
      '/images/facility/1721989890388.jpg',
      '/images/facility/1721990179236.jpg',
    ],
    name: 'Sports Activities',
    about: 'A state-of-the-art facility for various sports activities, including basketball, tennis, and more.',
  },
  {
    image: [
      '/images/facility/IMG-20240722-WA0035.jpg',
      '/images/facility/2.jpeg'
    ],
    name: 'Gurukul Aahar',
    about: 'Enjoy our delicious dishes made with fresh ingredients. Every bite is packed with flavor!',
  },
  {
    image: [
      '/images/facility/IMG-20240422-WA0057.jpg',
      '/images/facility/IMG-20240529-WA0008.jpg'
    ],
    name: 'Plantation Drive',
    about: 'A lush garden showcasing a variety of plants and flowers for relaxation and education.',
  },
  {
    image: [
      '/images/facility/1721989890611.jpg',
      '/images/facility/1.jpeg'
    ],
    name: 'Summer Camp',
    about: 'An interactive space designed for strategy meetings and team campaigns.',
  },
  // {
  //   image: [
  //     '/images/facility/1721989890388.jpg'
  //   ],
  //   name: 'Swimming Pool',
  //   about: 'A large swimming pool for both recreational swimming and competitive events.',
  // },
  // {
  //   image: [
  //     '/images/facility/drawing.jpg',
  //     '/images/facility/3.jpeg'
  //   ],
  //   name: 'Art Studio',
  //   about: 'A creative space for artists to express themselves through various art forms.',
  // },
  {
    image: [
      '/images/facility/IMG_20240823_152036_898.jpg',
      '/images/facility/4.jpeg',
      '/images/facility/5.jpeg'
    ],
    name: 'Janamashtami',
    about: 'A vibrant venue for cultural events, performances, and gatherings.',
  },
  {
    image: [
      '/images/facility/Copy of IMG_4245.JPG',
      '/images/facility/Copy of IMG_4328.JPG',
      '/images/facility/IMG_4412.JPG'
    ],
    name: 'Independence Day',
    about: 'A celebration of freedom and unity, honoring the nation with events, performances, and activities that reflect the spirit of independence.',
  },
  
];

const FacilityList = () => {

  const [imageIndices, setImageIndices] = useState(
    Array(Facility.length).fill(0)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setImageIndices((prevIndices) =>
        prevIndices.map((currentIndex, facilityIndex) => {
          const facilityImages = Facility[facilityIndex].image;
          return (currentIndex + 1) % facilityImages.length;
        })
      );
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='facilitybox'>
      <div className='facilityHeading'>Activities at Gurukul</div>
      <div className="facility-list">
        {Facility.map((facility, index) => (
          <div className="facility-card" key={index}>
            <div className='imgcontainer'>
              {facility.image.map((imgSrc, imgIndex) => (
                <img
                  key={imgIndex}
                  src={imgSrc}
                  alt={facility.name}
                  className={imgIndex === imageIndices[index] ? 'active' : ''}
                />
              ))}
            </div>
            <div className='detailcontainer'>
              <h3>{facility.name}</h3>
              <p>{facility.about}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FacilityList;
